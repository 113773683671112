@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @font-face {
  font-family: "poppins";
  src: local("poppins"),
    url(../assets/fonts/Poppins-Black.ttf) format("truetype");
} */
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Poppins";
  margin: 0;
  padding-bottom: 60px;
  overflow-x: hidden;
  font-size: 14px;
}
.h-full {
  min-height: 100%;
}

.flex {
  display: flex;
}
.j-c {
  display: flex;
  justify-content: center;
}

.a-c {
  display: flex;
  align-items: center;
}

.w-full {
  width: 100% !important;
}

.centralize {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-auto {
  margin: auto;
}

.card-link {
  color: black;
}

.card-link:hover {
  color: #006595;
}

.cancel-button,
.cancel-button:focus {
  background: #e8e8e8;
  color: black;
  border: 1px solid #e0d0d0;
}

.cancel-button:hover {
  border: 1px solid #e0d0d0;
  background: #f7f7f7;
  color: #424242;
}

.text-button,
.text-button:focus {
  border: 1px solid #006595;
  color: #006595;
  min-width: 40px;
}

.primary-button,
.primary-button:focus {
  background: rgba(85, 84, 164, 1);
  color: white;
  font-weight: bold;
  font-size: 15px;
  min-width: 40px;
  padding: 0 10px !important;
}

.text-button:hover,
.primary-button:hover {
  border: 1px solid rgb(62, 166, 201);
  color: white;
  background: rgb(62, 166, 201);
}

.danger,
.danger:focus {
  border: 1px solid #f2994a;
  color: #f2994a;
  background: white;
}

.danger:hover {
  border: 1px solid #f2994a;
  color: white;
  background: #f2994a;
}

.success,
.success:focus {
  border: 1px solid #10c469;
  color: #10c469;
  background: white;
}

.success:hover {
  border: 1px solid #10c469;
  color: white;
  background: #10c469;
}

.approve-button,
.approve-button:focus {
  width: 130px !important;
  border: 1px solid #10c469;
  color: #10c469;
  min-width: 40px;
}
.approve-button:hover {
  border: 1px solid #10c469;
  color: white;
  background: #10c469;
}

.disapprove-button,
.disapprove-button:focus {
  border: 1px solid #f2994a;
  color: #f2994a;
  min-width: 40px;
}
.disapprove-button:hover {
  border: 1px solid #f2994a;
  color: white;
  background: #f2994a;
}

.confirm-approve-button,
.confirm-approve-button:focus {
  width: 100px !important;
  border: 1px solid #10c469;
  background: #10c469;
  color: white;
  min-width: 40px;
}
.confirm-approve-button:hover {
  border: 1px solid #35db88;
  color: white;
  background: #35db88;
}

.confirm-disapprove-button,
.confirm-disapprove-button:focus {
  width: 110px !important;
  border: 1px solid #f2994a;
  background: #f2994a;
  color: white;
  min-width: 40px;
}
.confirm-disapprove-button:hover {
  border: 1px solid #ffa251;
  color: white;
  background: #ffa251;
}

.confirm-delete-button,
.confirm-delete-button:focus {
  width: 90px !important;
  border: 1px solid red;
  background: red;
  color: white;
  min-width: 40px;
}
.confirm-delete-button:hover {
  border: 1px solid rgb(255, 71, 71);
  color: white;
  background: rgb(255, 71, 71);
}

.completion-button {
  justify-content: space-around;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  height: 40px;
  background: white;
  padding: 0 10px;
  font-weight: bold;
  font-size: 14px;
  color: #424242;
}
.completion-button:hover {
  cursor: pointer;
  background: #f7f7f7;
}

.clear-search {
  border: none;
  background: none;
  font-size: 13px;
  font-weight: bolder;
  color: #f3994a;
}
.clear-search:hover {
  border: none;
  background: none;
}

.clear-search:active,
.clear-search:focus {
  background: none;
  color: #f3994a;
}

.delete-button,
.delete-button:focus {
  border: 1px solid red;
  color: red;
  min-width: 40px;
}

.delete-button:hover {
  border: 1px solid rgb(255, 69, 69);
  color: red;
  background: transparent;
}

.ingredient-advanced-search {
  color: #006595;
  text-decoration: underline;
  font-weight: bold;
}

.ingredient-advanced-search:hover {
  cursor: pointer;
  color: rgb(62, 166, 201);
}

.text-danger {
  color: #ff5b5b !important;
}

.text-muted {
  color: #98a6ad !important;
}

.text-primary {
  color: #188ae2 !important;
}

.text-warning {
  color: #f9c851 !important;
}

.text-success {
  color: #10c469 !important;
}

.file-uploaded {
  border: 1px solid #1980ff;
  color: #1980ff;
  min-width: 40px;
}

.file-disabled {
  border: 1px solid #999999;
  color: #999999;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: not-allowed;
}

.file-disabled:hover {
  border: 1px solid #999999;
  color: white;
  background: #999999;
  min-width: 40px;
}

.text-borderline,
.text-ok,
.text-danger {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border-radius: 4px;
  padding: 3px 9px;
  min-width: 61px;
  display: inline-block;
  text-align: center;
}

.text-borderline {
  color: rgba(235, 159, 87);
  background-color: rgba(235, 159, 87, 0.1);
}

.text-ok {
  color: rgba(129, 235, 87);
  background-color: rgba(129, 235, 87, 0.1);
}

.text-danger {
  color: #eb5757;
  background-color: rgba(235, 87, 87, 0.1);
}

.custom-modal-body .ant-modal-body {
  padding-top: 5px !important;
}

.costing-modal .modal-body {
  overflow: "hidden" !important;
}

/**--------------------------Form field Styling------------------------- **/

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0px;
}

.detail-view .ant-form-item-label {
  padding-bottom: 0px;
  /* text-align: center !important; */
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-popover-inner {
  display: flex;
  border-radius: 8px !important;
  height: 40px;
  padding: 0;
  align-items: center;
  width: fit-content;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  color: #777777;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: initial;
}

.custom-select .ant-select-selector {
  background: white !important;
  border: none !important;
  padding: 0 !important;
  font-weight: bold;
  padding-left: 0 !important;
}

.custom-select .ant-select-selection-item {
  color: rgb(22, 22, 22) !important;
  padding-left: 0 !important;
}

.custom-select .ant-select-arrow {
  display: none;
  width: 0 !important;
  padding-left: 0 !important;
}

.custom-select .ant-select-selector {
  display: flex;
  justify-content: center;
}

.view-date .ant-picker-input > input[disabled] {
  color: black;
  font-weight: bold;
  padding-left: 6px;
}

.view-date .anticon svg {
  display: none;
  width: 0;
}

.view-table .ant-table-cell {
  text-align: center;
  font-weight: bold !important;
}

.fixed-column-width {
  min-width: 190px !important;
}

.fixed-column-width-itemslno {
  min-width: 115px !important;
}

.custom-modal-footer .ant-modal-footer {
  height: 60px;
  padding-top: 10px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 49%;
  margin: 8px 0.5% 0;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.ant-upload-list-item-card-actions .anticon {
  color: red;
}

.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  color: #188ae2 !important;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #188ae2 !important;
}

.multiple-upload .ant-upload {
  width: 100% !important;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  justify-content: start;
  background: transparent !important;
}

.notification-s {
  background: #478dce;
  color: black !important;
  border-style: outset;
}

.notification-e {
  background: rgb(199, 60, 60);
  color: black !important;
  border-style: outset;
}
.notification-w {
  background: #f2994a;
  color: black !important;
  border-style: outset;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
.ant-input-prefix,
.ant-select-selection-search {
  display: flex !important;
  align-items: center !important;
  color: black !important;
}

.ant-select-selector {
  min-height: 40px !important;
  border-radius: 4px !important;
  font-family: "poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.ant-select-selection-placeholder {
  color: rgb(191, 191, 191) !important;
  font-size: 12px;
  font-family: "Poppins";
}

.ant-input:placeholder-shown {
  font-size: 12px;
  font-family: "Poppins";
}

.ant-input {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}

.ant-radio-button-wrapper,
.ant-radio-button-checked {
  border-radius: 4px !important;
  border-color: #006595 !important;
  border-left-width: 2px !important;
}

.ant-radio-button-wrapper:hover,
.ant-radio-button-input,
.ant-radio-button-checked,
.ant-radio-button-wrapper:active {
  background: #006595;
  color: white;
}

/* table */
.ant-table {
  font-size: 14px;
}

/* row data */
.ant-table-tbody > tr > td {
  height: 6px;
  padding: 14px;
}
/* row tags */
.my-tag {
  font-size: 12px;
}
.ant-table-thead > tr > th {
  height: 6px;
  padding: 10px;
}
.ant-select-dropdown {
  z-index: 1000;
}

.rpv-open-button,
.rpv-default-layout-sidebar-headers {
  display: none;
}

.nutrition-table .ant-table-tbody > tr > td {
  padding: 6px 12px;
}

/* for react datepicker */
.react-datepicker-wrapper {
  width: 100% !important;
  height: 40px;
  border-radius: 4px;
}

.react-datepicker__input-container:active {
  border: 1px solid #478dce;
  border-radius: 4px;
  font-family: "poppins";
  font-size: 13px;
}

.react-datepicker-popper {
  position: absolute;
  z-index: 1050;
}

.react-datepicker__close-icon {
  right: 5px !important;
}

.react-datepicker__close-icon::after {
  background-color: rgba(0, 0, 0, 0.25) !important;
  height: 12px !important;
  width: 12px !important;
  padding: 0px !important;
}

.react-datepicker-span {
  font-size: 13px;
  font-weight: 500;
  font-family: poppins;
  color: rgb(0, 0, 0);
}

.react-datepicker-disabled {
  cursor: not-allowed;
  border: 1px solid grey !important;
}

.react-datepicker-ignore-onclickoutside {
  border: 1px solid #478dce;
}

.react-datepicker-date-alert {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #ff4d4f;
}

.react-datepicker-date {
  width: 100% !important;
  height: 40px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
}

.react-datepicker-date:hover,
.react-datepicker-date:focus {
  border: 1px solid #006595;
}

.react-datepicker-disabled:hover,
.react-datepicker-disabled:focus {
  cursor: not-allowed;
  border: 1px solid grey !important;
}
/* end of datepicker */

/* radio option when selecting the recipe modal */
.radio-option {
  height: 40px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button,
.back-button:hover {
  background: transparent;
  color: "#006595 !important";
  border: none;
  font-size: 16px;
}
/* end of recipe modal */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* fa search btn */
.search-fa-btn,
.search-fa-btn:hover,
.search-fa-btn:focus {
  border: none;
  color: black;
  background: transparent;
}

/* border */
/* .rowClassName1 {
  background-color: #a1a1a1; */
/* border-radius: 10px;
  border-color: black;
  border-width: 3px;
  border-style: solid; */
/* border: 20px solid black;
} */
/* 
.rowClassName1 td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rowClassName1 td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
} */

/* antd collapse */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #006595;
  text-transform: uppercase;
  font-weight: bold;
}

.ant-collapse-arrow svg {
  color: #006595;
}

/* .ant-form-item-has-error {
  margin-bottom: 0 !important;
  position: relative;
}

.ant-form-item-explain-error div {
  position: absolute;
  bottom: 9px;
  margin: 0;
  padding: 0;
} */

.ant-typography {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0e0f17;
}
.ant-typography-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0e0f17;
  padding-bottom: 16px;
}
.upcoming-badge--text-container {
  padding-left: 8px;
}
.upcoming-badge--level-text {
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Sub Text */

  color: #5e656b;
}

.upcoming-badge--level-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Text */

  color: #0e0f17;
}

.section-wrapper .ant-col:not(:first-child) {
  padding: 0px;
  margin: 16px 0;
}

.platform-audience-count--text {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.platform-audience-count--type {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding-left: 4px;
  color: #5e656b;
}

.platform-audience-count--name {
  padding-left: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.calendar-create-event-button {
  /* position: absolute; */
  top: 72px;
  bottom: 0;
  right: 20px;
}

.calendar-create-event-creat-task-text {
  padding-left: 12px;
}

.progress-button-container {
  width: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
}

.progress-chart-container {
  margin-top: 24px;
}

.social-metrics-container {
  margin: 12px;
}

.second-card-container {
  margin-left: 24px;
}

.second-card-container--card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.second-card-container--card-badge {
  margin-top: 16px;
}

.second-card-container--card-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.second-card-container--card-level {
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  /* Sub Text */

  color: #5e656b;
}

.second-card-container--card-description {
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  /* Primary Color */

  color: #5554a4;
}

.typography-14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #141521;
}

.typography-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #5e656b;
}

.typography-18-primary {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #5554a4;
}

.typography-12-sub-text {
  font-size: 12px;
  line-height: 18px;
  color: #5e656b;
}

.typography-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #141521;
}
.typography-center {
  text-align: center;
}

.primary {
  color: #5554a4;
}

.badge-description-row {
  margin-top: 16px;
}

.inProgress {
  color: #ffb13c;
}

.upcoming {
  color: #5e656b;
}

.onboarding-question-container {
  background-color: white;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.onboarding-question-text-input-container {
  background-color: white;
  padding: 24px;
  height: 202px;
  width: 503px;
  box-shadow: 0px 4px 32px rgba(48, 47, 73, 0.1);
  border-radius: 8px;
}

.onboarding-thankyou-text-input-container {
  background-color: white;
  padding: 24px;
  height: 333px;
  width: 503px;
  box-shadow: 0px 4px 32px rgba(48, 47, 73, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-thankyou-container {
  /* background-color: white;
  padding: 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 80vh;
}

.onboarding-completion-thankyou-text {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Text */

  color: #141521;
}
.onboarding-completion-thankyou-title {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;

  /* Gradient */

  background: linear-gradient(90deg, #5554a4 -17.69%, #73c9bd 117.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.make-payment-container {
  margin-top: 100px;
}
.payment-details-card {
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(48, 47, 73, 0.04);
  border-radius: 8px;
  margin-right: 112px;
  margin-top: 24px;
  margin-left: 112px;
}

.payment-scheme-card {
  max-width: 298px;
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(48, 47, 73, 0.04);
  border-radius: 8px;
  margin-right: 40px;
  margin-top: 24px;
  position: relative;
}

.flex-row {
  display: flex;
  align-items: center;
}

.ml-4 {
  margin-left: 4px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Sub Text */

  color: #5e656b;
}

.font-thin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  leading-trim: both;
  text-edge: cap;

  /* Sub Text */

  color: #5e656b;
}

.text-2xl {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/

  /* Primary Color */

  color: #5554a4;
}

.font-plan-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text */

  color: #141521;
}

.pro-plan-button {
  background-color: white;
  color: #5554a4;
  border-color: #5554a4;
}

.subtotal-container {
  padding: 12px;
  gap: 8px;

  height: 103px;

  /* Border */

  border: 1px solid #e8e9eb;
  border-radius: 4px;
  margin: 12px 0px;
}

.subtotal-item-row {
  margin-bottom: 8px;
}

.border-bottom {
  border-bottom: 1px solid #e8e9eb;
}

.link-text-button--social {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.social-metrics--individual-card {
  height: 86px;
}

.child-card {
  margin: 20px 0;
}

.second-child {
  margin: 50px 0;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-family: Poppins;
  font-weight: 300;
}
