.auth-layout {
  /* background: url("../../assets/images/eat-2834549.jpg"); */
  /* background-size: cover; */
}

.poppins-font {
  font-family: poppins;
  font-weight: 500;
}

.home-scren {
}

.home-screen {
  margin-top: 37px;
}

.home-screen-mt-3 {
  margin-top: 30px;
}

.home-screen-mt-1 {
  margin-top: 10px;
}

.home-screen-login-button,
.home-screen-login-button:hover {
  border: 1px solid #1f1c1c;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #1f1c1c;
}

.home-screen-try-button,
.home-screen-try-button:hover {
  background: #3dd7e6;
  border-radius: 4px;
  border: none;
  margin-right: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #1f1c1c;
}

.home-screen-custom-margin {
  margin-top: 240px;
}

.home-screen-heading-first {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  text-align: center;
  color: #ffffff;
}

.home-screen-heading-paragraph,
.home-screen-heading-paragraph-black {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
}

.home-screen-heading-paragraph {
  color: #ffffff;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.home-screen-heading-paragraph-black {
  color: #1f1c1c;
}

.home-screen-sub-paragraph {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.home-screen-paragraph-text,
.home-screen-paragraph-text-white {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #4f5356;
}
.home-screen-paragraph-text-white {
  color: #ffffff;
  text-align: left;
}

.home-screen-margin-responsive {
  margin-top: 120px;
}

.home-screen-margin-responsive-brown {
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 80px;
  background: #f5f5f5;
}

.home-screen-eighty-percent {
  width: 80%;
  margin: 0 auto;
}

.home-screen-blue-box-margin {
  margin-top: 150px;
}

.home-screen-background-box {
  background: #e9f5fa;
  text-align: "center";
  height: 269px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.home-screen-box-image {
  max-width: 106px;
  position: relative;
  top: -50px;
}

.home-screen-heading-third {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #1f1c1c;
}

.home-screen-background-blue-box {
  background: #006595;
  border-radius: 15px;
}

.home-screen-blue-box-image {
  max-width: 80%;
  max-height: 252px;
  position: relative;
  top: -50px;
  left: 80px;
}

.home-screen-blue-box-heading-two {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #ffffff;
}

.home-screen-plan-box {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  height: 690px;
}

.home-screen-horizontal-line {
  border-top: 1px solid #006595;
  max-width: 70%;
  margin: 0 auto;
}

.home-screen-horizontal-line-second {
  border-top: 1px solid #006595;
  max-width: 50%;
  margin: 0 auto;
  margin-top: 20px;
}

.home-screen-big-text,
.home-screen-small-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  color: #006595;
}

.home-screen-small-text {
  font-size: 30px;
}

.home-screen-plan-pricing {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 10px;
}

.home-screen-plan-list {
  display: flex;
}

.home-screen-plan-list > p {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4f5356;
}

.home-screen-plan-list > img {
  height: 22px;
  width: 22px;
  margin-top: 10px;
  margin-right: 10px;
}

.home-screen-trial-button {
  background: #3dd7e6;
  border: none;
  border-radius: 4px;
  width: 85%;
  height: 60px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #1f1c1c;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
}

.home-screen-navigation-coursel {
  height: 600px;
}

.navigation-left-box {
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  height: 72px;
}

.navigation-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navigation-ul li {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #1f1c1c;
  cursor: pointer;
  padding-top: 20px;
  padding-left: 15px;
  height: 52px;
}

.navigation-ul span img {
  padding: 10px;
  width: 39px;
  height: 39px;
}

.navigation-active {
  background: #3dd7e6;
  box-sizing: content-box;
}

.home-screen-navigation-coursel-right {
  margin-left: 72px;
}

.navigation-list-paragraph {
  margin-top: 26px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4f5356;
}

.navigation-right-side-image {
  height: 400px;
  width: 100%;
}

/* for responsive */

@media only screen and (max-width: 925px) {
  .home-screen-background-blue-box {
    height: auto;
  }

  .home-screen-heading-first {
    font-size: 34px;
  }

  .home-screen-blue-box-heading-two {
    font-size: 30px;
  }

  .home-screen-custom-margin {
    margin-top: 100px;
  }

  .home-screen-blue-box-margin {
    margin-top: 50px;
  }

  .home-screen-blue-box-image {
    max-width: 300px;
    max-height: 252px;
    object-fit: cover;
    position: relative;
    top: 0px;
    left: 0px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .home-screen-logo {
    padding-left: 35px;
  }
  .home-screen-main-paragraph {
    font-size: 28px;
  }
  .home-screen-sub-paragraph {
    font-size: 22px;
  }
}
