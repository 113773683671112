.header-container {
  padding: 10px 15px 0 30px;
  background: white;
  height: 64px;
  border-bottom: 1px solid rgb(248, 248, 248);
}

.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #006595;
}
.nav-logout-wrapper {
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}
.logout-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Sub Text */

  color: #5e656b;
}

.text-container {
  margin-left: 4px;
}
.header-container .header-email-text {
  font-size: 12px;
  line-height: 15px;
  color: #5e656b;
}
.header-container .header-level-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0e0f17;
}
.header-container .header-name-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0e0f17;
}
.menu-item {
  width: 6.2rem;
  height: 84px;
  font-size: 16px;
  margin-left: 1rem;
  color: black;
}

.menu-item-active {
  border-bottom: 3px solid #457b9d;
  box-sizing: border-box;
  font-size: 16px;
  color: #457b9d;
  padding-top: 3px;
}

.menu-item:hover {
  cursor: pointer;
  border-bottom: 3px solid #457b9d;
  box-sizing: border-box;
  font-size: 16px;
  color: #457b9d;
  padding-top: 3px;
}

.avatar {
  height: 2.75rem;
  width: 2.75rem;
  margin-right: 1rem;
}

.header-username {
  width: 90px;
  height: 24px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #353745;
}

.user-dropdown:hover {
  cursor: pointer;
  color: #457b9d;
}

.dropdown-item:hover:hover {
  cursor: pointer;
  color: white;
  background: #457b9d;
}

.dropdown-menu {
  padding: 0;
}

.topbar {
  background: #fff;
}

.logo-container {
  height: 64px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 0px 20px;
}

.flogo {
  width: 150px;
  height: auto;
}

.slogo {
  width: 40px;
  height: 40px;
}

.nav-menu-item {
  width: 89%;
  height: 56px;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #353745;
  position: relative !important;
}

.nav-menu-item:hover {
  height: 56px;
  color: #f6f6f6;
  /* background: #f5f2ff; */
  box-sizing: content-box;
  /* box-shadow: inset 0 0 20px#aad8ee; */
  border-radius: 0px 100px 100px 0px;
}

.collapsed-menu {
  width: 96% !important;
}

.active {
  height: 56px;
  color: #f6f6f6;
  background: #f5f2ff !important;
  box-sizing: content-box;
  border-radius: 0px 100px 100px 0px;
}

.nav-menu-label-active {
  margin-left: 25px;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  color: #5554a4;
  display: flex;
  align-items: center;
}
.nav-menu-label {
  margin-left: 25px;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  color: #5e656b;
  display: flex;
  align-items: center;
}

.nav-menu-image {
  margin-right: 18px;
  float: left;
  height: 24px;
  width: 24px;
}

.sub-label {
  margin-left: 50px;
}

.collapsed-sub-label {
  margin-left: 35px !important;
}

.sub {
  background: #86858517;
  border-left: 3px solid #3d3d3d21;
  box-sizing: content-box;
  box-shadow: inset 0 0 10px #4242422a;
}

.sider {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.11);
  -webkit-transition: all 0s;
  transition: all 0s;
}

.nav-menu-wrapper::-webkit-scrollbar {
  width: 4px !important;
  border-radius: 4px !important;
}
.nav-menu-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px !important;
}

.nav-menu-wrapper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 2px !important;
}

.nav-menu-wrapper {
  overflow: auto;
  height: calc(100vh - 64px);
  flex: 0 1 auto;
  background-color: #ffffff;
}

.side-menu-toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1000;
  background: white;
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.397);
  border-radius: 50%;
  top: 105px;
  right: -15px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  font-size: 14px;
}

.side-menu-toggle-button:hover {
  cursor: pointer;
  color: #359fc1;
  background: rgb(243, 237, 237);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.ant-table-tbody > tr > td {
  color: black;
}

.ant-pagination-options .ant-select-selector {
  min-height: 32px !important;
}

.ant-table-pagination .ant-pagination {
  padding-right: 14px;
}

.ant-pagination-item,
.ant-pagination-disabled .ant-pagination-item-link {
  border-radius: 4px !important;
}
.level-container {
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 6px 12px 6px 6px;
  width: 109px;
  justify-content: space-between;
}

.header-username .header-right-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
